<template>
  <div class="container toefl-list">
    <el-menu
      default-active="1"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1"
        ><b>{{ $t("toefl.TOEFL_Practices") }}</b></el-menu-item
      >
      <el-menu-item index="2"
        ><b>{{ $t("toefl.TOEFL_Results") }}</b></el-menu-item
      >
    </el-menu>
    <div class="toefl-header">
      <div class="title">
        <div class="head-title" v-if="isNew === 1">
          {{ $t("toefl.index.title_new") }}
        </div>
        <div class="head-title" v-if="isNew === 0">
          {{ $t("toefl.index.title_old") }}
        </div>
      </div>
      <div class="change-version">
        <el-button v-if="isNew === 1" @click="changeVersion(0)"
          ><i class="el-icon-refresh"></i>
          {{ $t("toefl.index.switch_old") }}</el-button
        >
        <el-button v-if="isNew === 0" @click="changeVersion(1)"
          ><i class="el-icon-refresh"></i>
          {{ $t("toefl.index.switch_new") }}</el-button
        >
        <a
          class="toefl-link"
          target="_blank"
          href="https://blog.ivy-way.com/new-toefl-ibt-test-content/"
          style="margin-left:10px"
        >
          <i class="fas fa-question-circle"></i>
          {{ $t("toefl.index.check_diff") }}
        </a>
        <!-- <el-button type="primary" @click="viewTestResults">
          <i class="el-icon-view"></i>
          {{ $t("toefl.typeList.testresults") }}
        </el-button> -->
      </div>
    </div>
    <toefl-list-title
      :testType="testType"
      :singleType.sync="singleType"
      :isNew="isNew"
    ></toefl-list-title>
    <toefl-list-body :testType="testType" :isNew="isNew"></toefl-list-body>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import ToeflListTitle from "@/views/toefl/toeflList/ToeflListTitle";
import ToeflListBody from "@/views/toefl/toeflList/ToeflListBody";

export default {
  metaInfo() {
    return {
      title: "TOEFL | Mock"
    };
  },
  components: {
    ToeflListTitle,
    ToeflListBody
  },

  mixins: [],

  props: [],
  data() {
    return {
      cardsTotal: 0
    };
  },
  computed: {
    testType() {
      let testType = "mock";
      if (this.$route.query.testType === undefined) {
        testType = "mock";
      } else {
        testType = this.$route.query.testType;
      }
      return testType;
    },
    singleType() {
      if (this.$route.query.testType == "quick") {
        return this.$route.query.singleType;
      }
      return "";
    },
    isNew() {
      let isNew = 1;
      if (this.$route.query.isNew === undefined) {
        isNew = 1;
      } else {
        isNew = parseInt(this.$route.query.isNew);
      }
      return isNew;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    changeVersion(val) {
      this.routerPush({
        name: "ToeflList",
        query: {
          testType: this.testType,
          isNew: val
        }
      });
    },
    viewTestResults() {
      let type = this.testType;
      if (type == "mock") {
        type = "full_mock";
      }
      if (type == "quick") {
        type = "quick_mock";
      }
      this.$router.replace({
        name: "ToeflTestResults",
        query: {
          type: type,
          page: 1
        }
      });
    },
    handleSelect(index) {
      if (index === "2") {
        this.viewTestResults();
      }
    }
  }
};
</script>
<style scoped>
.toefl-header {
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 15px;
}
.toefl-list {
  margin-bottom: 10px;
}
.toefl-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.head-title {
  font-size: 30px;
  font-weight: 700;
  color: #43a06b;
}
.title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.toefl-link {
  font-size: 14px;
}
</style>
