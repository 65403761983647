<template>
  <div class="tpos" @click="startTest()">
    <div class="l-block">
      <div class="ibottom">
        <label class="ileft">{{ title }}</label>
      </div>
    </div>
    <div class="r-block">
      <a class="start_test start">Start</a>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},
  mixins: [],
  props: ["test", "title", "card_title", "testType", "version"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    startTest() {
      if (this.test === undefined) {
        this.routerPush({
          name: "SetTest",
          query: {
            title: this.card_title,
            testType: this.testType,
            version: this.version
          }
        });
      } else {
        let id = this.test.id;
        this.routerPush({
          name: "SetTest",
          query: {
            chapter_id: id,
            version: this.version
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.tpos {
  margin: 10px 0;
  cursor: pointer;
  border-radius: 10px;
  height: 80px;
  box-sizing: border-box;
  background-color: #edf1f8;
  border-bottom: 2px solid #dadee5;
  color: #808080;
  transition: 0.2s 0s;
  border-radius: 5px;
}
.tpos:hover {
  box-shadow: 3px 5px 10px #aaa;
  color: #43a06b;
}
.tpos:hover .l-block {
  display: none;
}

.tpos:hover .r-block {
  display: block;
}
.tpos:hover .start {
  color: #fff !important;
}

.ibottom {
  text-align: center;
}

.l-block {
  width: 100%;
  height: 80px;
  display: block;
  float: left;
}
.l-block .ileft {
  margin-left: 0;
  line-height: 80px;
  text-align: center;
}

.l-block p {
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px;
}

.itop h3,
.l-block p,
.ibottom label {
  display: inline-block;
}
.r-block {
  bottom: 28px;
  top: 28px;
  width: 100%;
  display: none;
  position: relative;
  height: 80px;
  float: right;
  text-align: center;
}

.r-block a:first-child {
  display: block;
  top: 0px;
  background-color: #43a06b;
}
.r-block a {
  width: 86px;
  height: 28px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  border-radius: 50em;
  color: #fff;
  text-align: center;
  line-height: 29px;
  transition: 0.5s 0s;
}
a {
  user-select: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
</style>
