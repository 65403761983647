<template>
  <div>
    <!-- <h2 class="text-center">
      {{ $t("toefl.typeList." + testType) }}
    </h2> -->
    <hr />
    <div class="row">
      <div class="col-sm-2 text-right ivy-type-title">
        {{ $t("toefl.mock_test") }}
      </div>
      <div class="col-sm-10 btn-list">
        <div class="btn-item-type">
          <a @click="selectType('mock')">
            <label :class="testType == 'mock' ? 'btn active' : 'btn'">
              {{ $t("toefl.typeList.mock") }}
            </label>
          </a>
        </div>
        <div class="btn-item-type">
          <a @click="selectType('quick')">
            <label :class="testType == 'quick' ? 'btn active' : 'btn'">
              {{ $t("toefl.typeList.quick") }}
            </label>
          </a>
        </div>
        <div class="btn-item-type">
          <a @click="selectType('reading')">
            <label
              :class="
                testType != 'mock' && testType != 'quick' ? 'btn active' : 'btn'
              "
            >
              {{ $t("toefl.single_test") }}
            </label>
          </a>
        </div>
      </div>
    </div>

    <div class="row" v-if="testType != 'mock' && testType != 'quick'">
      <div class="col-sm-2 text-right ivy-type-title">
        {{ $t("toefl.single_test") }}
      </div>
      <div class="col-sm-10 btn-list">
        <div class="btn-item">
          <a @click="selectType('reading')">
            <label :class="testType == 'reading' ? 'btn active' : 'btn'">
              {{ $t("toefl.typeList.reading") }}
            </label></a
          >
        </div>
        <div class="btn-item">
          <a @click="selectType('listening')">
            <label :class="testType == 'listening' ? 'btn active' : 'btn'">
              {{ $t("toefl.typeList.listening") }}
            </label></a
          >
        </div>
        <div class="btn-item">
          <a @click="selectType('speaking')">
            <label :class="testType == 'speaking' ? 'btn active' : 'btn'">
              {{ $t("toefl.typeList.speaking") }}
            </label></a
          >
        </div>
        <div class="btn-item">
          <a @click="selectType('writing')">
            <label :class="testType == 'writing' ? 'btn active' : 'btn'">
              {{ $t("toefl.typeList.writing") }}
            </label></a
          >
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  components: {},

  mixins: [],

  props: ["testType", "isNew"],
  data() {
    return {
      typeList: this.$t("toefl.typeList")
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    selectType(testType) {
      this.$router.replace({
        name: "ToeflList",
        query: {
          testType: testType,
          isNew: this.isNew
        }
      });
    }
  }
};
</script>

<style scoped>
.ivy-type-title {
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  margin: 10px 0;
}
.btn-list {
  display: flex;
  justify-content: space-between;
}
.btn-item {
  width: 200px;
}
.btn-item-type {
  width: 280px;
}
.btn {
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #43a06b;
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  margin: 10px 0;
}
.btn:hover,
.active {
  color: white;
  background: #43a06b;
  box-shadow: 1px 3px 6px #aaa;
}
</style>
