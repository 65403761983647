<template>
  <div>
    <div class="text-center">
      <el-pagination
        @current-change="setPage"
        :page-size="per_page"
        :current-page="page"
        :total="cardsTotal"
        :pager-count="5"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <div v-if="testType == 'mock' || testType == 'quick'" class="row">
      <div
        v-for="(test, index) in cards"
        :key="index"
        class="col-xs-12 col-md-6"
      >
        <mock-card
          :test="test"
          :testType="testType"
          :version="isNew"
        ></mock-card>
      </div>
    </div>
    <div
      v-if="
        testType == 'reading' ||
          testType == 'listening' ||
          testType == 'speaking' ||
          testType == 'writing'
      "
    >
      <div v-for="(card, key) in cards" :key="key">
        <h3>{{ card.title }}</h3>
        <div class="row">
          <div class="col-xs-12 col-md-4">
            <PassageCard
              :title="singleSection[testType][lang]"
              :card_title="card.title"
              :testType="testType"
              :version="isNew"
            ></PassageCard>
          </div>
        </div>
        <div class="row">
          <div
            v-for="(test, index) in card.chapters"
            :key="index"
            class="col-xs-12 col-md-4"
          >
            <PassageCard
              :test="test"
              :title="test.title[lang]"
              :card_title="card.title"
              :testType="testType"
              :version="isNew"
            ></PassageCard>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div class="text-center">
      <el-pagination
        @current-change="setPage"
        :page-size="per_page"
        :current-page="page"
        :total="cardsTotal"
        :pager-count="5"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import MockCard from "@/views/toefl/toeflList/components/MockCard.vue";
import PassageCard from "@/views/toefl/toeflList/components/PassageCard.vue";
import Toefl from "@/apis/toefl.js";

export default {
  components: {
    MockCard,
    PassageCard
  },

  mixins: [],

  props: ["testType", "isNew"],
  data() {
    return {
      cards: [],
      cardsTotal: 0,
      per_page: 0,
      singleSection: {
        reading: {
          cn: "完整单元（3 篇文章）",
          tw: "完整單元（3 篇文章）",
          en: "Single Section (3 Passages)"
        },
        listening: {
          cn: "完整单元（6 篇文章）",
          tw: "完整單元（6 篇文章）",
          en: "Single Section (6 Passages)"
        },
        speaking: {
          cn: "完整单元（6 篇文章）",
          tw: "完整單元（6 篇文章）",
          en: "Single Section (6 Passages)"
        },
        writing: {
          cn: "完整单元（2 篇文章）",
          tw: "完整單元（2 篇文章）",
          en: "Single Section (2 Passages)"
        }
      }
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"]),
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    lang() {
      let lang = "";
      if (this.langValue == "EN-US") {
        lang = "en";
      }
      if (this.langValue == "ZH-CN") {
        lang = "cn";
      }
      if (this.langValue == "ZH-TW") {
        lang = "tw";
      }
      return lang;
    }
  },
  watch: {
    testType() {
      this.getToefls();
    },
    page() {
      this.getToefls();
    }
  },

  mounted() {
    this.getToefls();
  },

  methods: {
    async getToefls() {
      let type = this.testType;
      if (type === "mock" || type === "quick" || type === "single") {
        type = "mock";
      }
      const res = await Toefl.getToefls({
        type: type,
        page: this.page,
        is_new: this.isNew
      });
      this.per_page = res.per_page;
      this.cards = res.data;
      this.cardsTotal = res.total;
    },
    setPage(page) {
      this.routerPush({
        name: "ToeflList",
        query: {
          testType: this.testType,
          page: page,
          isNew: this.isNew
        }
      });
    }
  }
};
</script>

<style scoped></style>
